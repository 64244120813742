import React, { useEffect, useRef, useState, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { captureException } from "@sentry/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCoach } from "../../redux/actions/coach";

// Components
import { useTheme } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../api/splide-goalswon.css";

// Firestore
import { addRecurrentGoal } from "../../api/firestore/recurrentGoals";
import { addTarget } from "../../api/firestore/targets";
import { addMonthAwait, isMonthCreated } from "../../api/firestore/months";
import { addWelcomeMessage, addWelcomeVideo } from "../../api/firestore/chats";
import {
  getPreregisteredUser,
  deletePreregisteredUser,
} from "../../api/firestore/users";
import { updateUserAwait } from "../../api/firestore/users";

//Screens
import Screen0 from "./screen0";
import Screen1 from "./screen1";
import Screen2 from "./screen2";
import Screen3 from "./screen3";
import Screen4 from "./screen4";
import Screen5 from "./screen5";
import Screen6 from "./screen6";
import Screen7 from "./screen7";
// import Screen8 from "./screen8";

// Analytics
import { analytics } from "../../api/firebase";

// Own api
import { USER_TYPE } from "../../api/constants";
import { sendOnboardingSlackNotification } from "../../api/helper";
import welcome_tutorial from "../../assets/sounds/welcome_tutorial.wav";
import { playSound } from "../../api/filesHandler";
import "./style.scss";

const TutorialNavigation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const tutorialRef = useRef();

  // Redux
  const user = useSelector((state) => state.user.user);
  const coach = useSelector((state) => state.coach.coach);
  const quiz = useSelector((state) => state.quiz.quiz);
  const tags = useSelector((state) => state.tags.tags);

  // States
  // Set starting goals corresponding to tag attached to user's quiz. If no quiz, set default
  const [startingGoals, setStartingGoals] = useState(
    tags?.find((tag) => tag.id === quiz?.tag || "default")?.startingGoals
  );
  const [targetName, setTargetName] = useState("");
  const [isLoadingTutorialEnd, setIsLoadingTutorialEnd] = useState(false);

  useEffect(() => {
    if (user?.id && user?.coach) {
      dispatch(fetchCoach(user?.coach));
    }
  }, [user?.id, user?.coach]);

  useEffect(() => {
    playSound(welcome_tutorial);
    analytics.logEvent("tutorial_begin");
  }, []);

  const timeToFinishTutorial = () => {
    if (!user?.createdTime) return "N/A";

    const startDate = moment(
      user?.createdTime?.toDate && user.createdTime.toDate()
    );
    const endDate = moment();
    const diffTime = moment(endDate).diff(startDate);

    const duration = moment.duration(diffTime);
    const days = duration.days(),
      hrs = duration.hours(),
      mins = duration.minutes(),
      secs = duration.seconds();

    return (
      (days ? days + " days " : "") +
      hrs +
      " hrs " +
      mins +
      " mins " +
      secs +
      " secs"
    );
  };

  const finishTutorial = useCallback(async () => {
    setIsLoadingTutorialEnd(true);

    const startTime = Date.now(); // Record the start time

    // Add startingGoals
    await Promise.all(
      startingGoals?.length &&
        startingGoals.map(async (item, index) => {
          if (item.isSelected) {
            await addRecurrentGoal({
              name: item.name,
              position: index,
              isDone: false,
              tag: item.tag,
              description: item.description,
              startDate: moment(),
              recurrencePattern: "daily",
              days: [],
              endDate: null,
              isRolledOver: item.isRolledOver,
            });
          }
        })
    );

    // Add first target
    const monthDate =
      moment().startOf("day").format("YYYYMMDD") <
      moment().endOf("month").subtract(6, "days").format("YYYYMMDD")
        ? moment()
        : moment().add(1, "month"); // If today is one of the last 7 days of the month, default to next month,

    await addTarget({
      name: targetName.trim(),
      isDone: false,
      tag: null,
      description: "",
      date: monthDate,
    });

    const month = await isMonthCreated(monthDate);
    if (!month) {
      await addMonthAwait(monthDate);
    }

    // Fetch the client's preregistered user doc for necessary stuff
    const preregisteredUser = await getPreregisteredUser(user.email);

    // Send corresponding slack notifications
    if (user?.coach && user?.type && user?.type !== USER_TYPE.INACTIVE) {
      // Only send notification if client:
      // has type and it's different from "inactive"

      // -------------------- Send to general channel (#onboarding) --------------------
      sendOnboardingSlackNotification(
        `✅ FINISHED TUTORIAL: ${timeToFinishTutorial()}
   
${
  preregisteredUser?.customMessage
    ? "👋 welcome message was customized by coach"
    : "🥺 welcome message wasn't customized by coach"
}

New client: ${user?.name}
Coach: ${coach?.name}

<https://goalswon-coaching-app.web.app/client/${
          user?.id
        } | Send a chat message>`
      );

      // -------------------- Send to coach channel (#coach-X-new-client) --------------------
      if (coach?.slackNewClients) {
        fetch(coach.slackNewClients, {
          method: "POST",
          body: JSON.stringify({
            text: `✅ FINISHED TUTORIAL: ${timeToFinishTutorial()}
   
${
  preregisteredUser?.customMessage
    ? "👋 welcome message was customized by coach"
    : "🥺 welcome message wasn't customized by coach"
}
          
New client: ${user?.name}

<https://goalswon-coaching-app.web.app/client/${
              user?.id
            } | Send a chat message>`,
          }),
        })
          .then((response) => {
            // console.log("RESPONSE: ", response.status)
          })
          .catch((error) => {
            captureException(
              "Error while sending finished tutorial slack notification",
              error
            );
            console.log(
              "Error while sending finished tutorial slack notification: " +
                error
            );
          });
      }
    }

    // Set flag to indicate if client received a custom welcome message (and when) or not
    if (preregisteredUser?.customMessage) {
      await updateUserAwait({
        welcomeMessageCustomizedByCoach: moment(),
      });
    }

    // Set welcome message
    const welcomeMessageToAdd = preregisteredUser
      ? preregisteredUser?.type &&
        preregisteredUser?.type !== USER_TYPE.INACTIVE
        ? preregisteredUser?.customMessage || coach?.defaultWelcomeMessage // Pre-registered premium/EA/Free/etc user
        : coach?.defaultWelcomeMessageWithCTA // Pre-registered with no type or inactive user
      : coach?.defaultWelcomeMessageWithCTA; // Free user

    if (welcomeMessageToAdd) {
      await addWelcomeMessage(coach?.id, coach?.name, welcomeMessageToAdd);
    }

    // Set welcome video
    if (user?.coach && user?.type && user?.type !== USER_TYPE.INACTIVE) {
      if (coach?.welcomeVideo?.url) {
        await addWelcomeVideo(coach?.id, coach?.name, {
          ...coach?.welcomeVideo,
          isWelcomeVideo: true,
        });
      }
    } else {
      // If client is not premium, send welcome video with CTA
      if (coach?.welcomeVideoWithCta?.url) {
        await addWelcomeVideo(coach?.id, coach?.name, {
          ...coach?.welcomeVideoWithCta,
          isWelcomeVideoWithCta: true,
        });
      }
    }

    // Delete the no longer used pre-registered user from db
    if (preregisteredUser) {
      await deletePreregisteredUser(user?.email);
    }

    // Log tutorial complete event
    analytics.logEvent("tutorial_complete");

    // Calculate the elapsed time
    const elapsedTime = Date.now() - startTime;

    // Wait if less than 3 seconds have passed
    if (elapsedTime < 3000) {
      await new Promise((resolve) => setTimeout(resolve, 3000 - elapsedTime));
    }

    // TODO - CHECK: Should I take another approach? Should wait like 3 secs to set the finishedTutorial var, maybe use the loader to redirect or even a batch write for all this

    // Set field in doc for tutorial completion
    await updateUserAwait({ finishedTutorial: moment() });

    setIsLoadingTutorialEnd(true);
  }, [
    startingGoals,
    targetName,
    user?.id,
    user?.email,
    user?.name,
    user?.type,
    user?.coach,
    coach?.id,
    coach?.name,
    coach?.welcomeVideo,
    coach?.welcomeVideoWithCta,
    coach?.defaultWelcomeMessage,
    coach?.defaultWelcomeMessageWithCTA,
    coach?.slackNewClients,
  ]); // Only re-create if these dependencies change

  return (
    <>
      <div style={{ backgroundColor: theme.palette.background.main }}>
        <Splide
          options={{
            arrows: false, // Hide arrows
            pagination: false, // Hide dots
            drag: false,
            keyboard: false,
          }}
          ref={tutorialRef}
        >
          <SplideSlide>
            <Screen0 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen1 tutorialRef={tutorialRef} coach={coach} />
          </SplideSlide>
          <SplideSlide>
            <Screen2 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen3 tutorialRef={tutorialRef} quiz={quiz} />
          </SplideSlide>
          <SplideSlide>
            <Screen4 tutorialRef={tutorialRef} coach={coach} />
          </SplideSlide>
          <SplideSlide>
            <Screen5 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen6
              tutorialRef={tutorialRef}
              startingGoals={startingGoals}
              setStartingGoals={setStartingGoals}
            />
          </SplideSlide>
          <SplideSlide>
            <Screen7
              setTargetName={setTargetName}
              finishTutorial={finishTutorial}
              isLoadingTutorialEnd={isLoadingTutorialEnd}
            />
          </SplideSlide>
        </Splide>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        // pauseOnFocusLoss
        // pauseOnHover
        theme="light"
      />
    </>
  );
};

export default React.memo(TutorialNavigation);

//---------------------

// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// import "./style.scss";

// // import required modules
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

// const TutorialNavigation = () => {
//   return (
//     <>
//       <Swiper
//         cssMode={true}
//         navigation={true}
//         pagination={true}
//         mousewheel={true}
//         keyboard={true}
//         modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//       </Swiper>
//     </>
//   );
// };

// export default TutorialNavigation;

//----------------------------------------

// import React from "react";

// // ---- Swiper ----
// // Core modules imports are same as usual
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
// // Direct React component imports
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// // Styles must use direct files imports
// import "swiper/swiper.scss"; // core Swiper
// import "swiper/modules/navigation/navigation.scss"; // Navigation module
// import "swiper/modules/pagination/pagination.scss"; // Pagination module

// import { useTheme } from "@mui/material";

// //Screens
// import Screen1 from "./screen1";
// // import Screen2 from "./screen2";
// // import Screen3 from "./screen3";
// // import Screen4 from "./screen4";
// // import Screen5 from "./screen5";
// // import Screen6 from "./screen6";
// // import Screen7 from "./screen7";

// import { colors } from "../../api/constants";
// import "./style.scss";

// const TutorialNavigation = () => {
//   const theme = useTheme();

//   return (
//     <Swiper
//       cssMode={true}
//       navigation={true}
//       pagination={true}
//       mousewheel={true}
//       keyboard={true}
//       modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//       className="mySwiper"
//     >
//       <SwiperSlide>xd</SwiperSlide>

//       {/* <Screen1 /> */}
//       {/* <Screen2 /> */}
//       {/* <Screen3 /> */}
//       {/* <Screen4 /> */}
//       {/* <Screen5 /> */}
//       {/* <Screen6 /> */}
//       {/* <Screen7 /> */}
//     </Swiper>
//   );
// };

// export default TutorialNavigation;
