import React, { useCallback } from "react";
import moment from "moment-timezone";

// Analytics
import { analytics } from "../../api/firebase";

// Components
import {
  useTheme,
  Dialog,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import { USER_TYPE, colors } from "../../api/constants";
import "./style.scss";

const CoachInfoOverlay = ({
  isVisible,
  setIsVisible,
  setIsCoachStatusOverlayVisible,
  userType,
  coachName,
  coachDescription,
  coachFullImage,
  coachLatestMilestone,
  coachNextTarget,
  coachFavouriteQuote,
  coachFunFact,
  coachCoachingApproach,
  coachLocation,
  coachTimezone,
  coachLastStatusImage,
}) => {
  const theme = useTheme();

  const onDonePressed = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleStatusPress = () => {
    setIsVisible(false);
    setIsCoachStatusOverlayVisible(true);

    // Log analytics event
    analytics.logEvent("opened_coach_status", {
      source: "coachProfile",
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      maxWidth="xs"
      fullWidth
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="coach-img-and-info-modal-container">
          {coachFullImage ? (
            <div className="coach-img-container">
              <img className="coach-img" src={coachFullImage} alt="Coach" />
            </div>
          ) : (
            <div className="coach-img-container-no-img" />
          )}

          <div
            className="name-and-bio-container"
            style={{ backgroundColor: theme.palette.quaternary.main }}
          >
            {coachName ? (
              <div>
                <label
                  className="coach-name"
                  style={{ color: theme.palette.text.main }}
                >
                  {coachName}
                </label>
              </div>
            ) : null}
            {coachDescription ? (
              <div className="coach-description-container">
                <label
                  className="coach-description"
                  style={{ marginTop: 20, color: theme.palette.text.main }}
                >
                  {coachDescription}
                </label>
              </div>
            ) : null}

            {coachLastStatusImage &&
            userType &&
            userType !== USER_TYPE.INACTIVE ? (
              <div className="coach-status-img-container">
                <div
                  className={
                    theme.palette.mode === "dark"
                      ? "coach-status-img-container-with-border-dark-mode"
                      : "coach-status-img-container-with-border-light-mode"
                  }
                  onClick={handleStatusPress}
                  // FIXED BORDER
                  style={{
                    background:
                      coachLastStatusImage &&
                      userType &&
                      userType !== USER_TYPE.INACTIVE
                        ? `linear-gradient(${theme.palette.secondaryBackground.main}, ${theme.palette.secondaryBackground.main}) padding-box,
                    linear-gradient(to bottom, ${colors.milestoneGradientFirst}, ${colors.milestoneGradientSecond}) border-box`
                        : "transparent",
                  }}
                >
                  <img
                    className="coach-status-img"
                    src={coachLastStatusImage}
                    alt="coach"
                    height="60"
                    width="60"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="coach-info-overlay-content">
          {coachLatestMilestone ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                LATEST MILESTONE
              </span>
              <span className="text">{coachLatestMilestone}</span>
            </>
          ) : null}

          {coachNextTarget ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                NEXT TARGET
              </span>
              <span className="text">{coachNextTarget}</span>
            </>
          ) : null}

          {coachFavouriteQuote ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                FAVORITE QUOTE
              </span>
              <span className="text">{coachFavouriteQuote}</span>
            </>
          ) : null}

          {coachFunFact ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                FUN FACT
              </span>
              <span className="text">{coachFunFact}</span>
            </>
          ) : null}

          {coachCoachingApproach ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                COACH APPROACH
              </span>
              <span className="text">{coachCoachingApproach}</span>
            </>
          ) : null}

          {coachLocation ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                LOCATION
              </span>
              <span className="text">{coachLocation}</span>
            </>
          ) : null}

          {coachTimezone ? (
            <>
              <span
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                CURRENT TIME
              </span>
              <span className="text">
                {moment().tz(coachTimezone).format("h:mm a")}
              </span>
            </>
          ) : null}
        </div>
      </DialogContent>

      <Divider />

      <div className="modal-footer" onClick={onDonePressed}>
        <Button
          sx={{
            borderRadius: 0,
            height: 50,
          }}
          color="primary"
          onClick={() => {
            setIsVisible(false);
          }}
        >
          Done
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(CoachInfoOverlay);
