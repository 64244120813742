import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import {
  useTheme,
  Dialog,
  Slide,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCoachStatuses } from "../../redux/actions/coachStatus";

// Firestore
import { updateCoachStatus } from "../../api/firestore/coachStatuses";

// Own api
import { howLongAgo } from "../../api/helper";
import { lightTheme } from "../../api/theme";
import "./style.scss";

const StatusOverlay = ({
  isVisible,
  setIsVisible,
  userId,
  coachId,
  coachStatusesCounter,
}) => {
  const theme = useTheme();

  // Redux
  const dispatch = useDispatch();
  const statuses = useSelector((state) => state.coachStatus.coachStatuses);
  const isLoadingStatuses = useSelector((state) => state.coachStatus.isLoading);

  // States
  const [currentStatusIndex, setCurrentStatusIndex] = useState(
    coachStatusesCounter - 1
  );
  const [isPreparingStatus, setIsPreparingStatus] = useState(true);

  // Variables
  const currentStatus =
    currentStatusIndex !== null && statuses?.length
      ? statuses[currentStatusIndex]
      : null;

  useEffect(() => {
    if (isVisible) {
      setCurrentStatusIndex(coachStatusesCounter - 1);
      dispatch(fetchCoachStatuses(coachId));
      // dispatch(fetchSingleCoachStatus(coachId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, coachId]);

  useEffect(() => {
    setCurrentStatusIndex(statuses?.length ? statuses.length - 1 : null);
    // setTotalStatusesNumber(statuses?.length);
  }, [statuses]);

  useEffect(() => {
    if (currentStatus?.image) {
      // Preload the image
      const img = new Image();
      img.src = currentStatus.image;
      img.onload = () => {
        setIsPreparingStatus(false);

        // Mark status as seen
        if (
          !currentStatus?.seen?.length ||
          !currentStatus.seen.find((el) => el === userId)
        ) {
          updateCoachStatus(coachId, currentStatus?.id, {
            seen: [...(currentStatus?.seen ?? []), userId],
          });
        }
      };
    }
  }, [currentStatus?.id]); // Only run when id changes, ie the status changes. If just `status` was used, it would cause re-renders when other users update the `seen` property

  const handleLeftPressed = () => {
    if (currentStatusIndex > 0) {
      setCurrentStatusIndex((prev) => prev - 1);
      setIsPreparingStatus(true);
    }
  };

  const handleRightPressed = () => {
    if (currentStatusIndex < statuses?.length - 1) {
      setCurrentStatusIndex((prev) => prev + 1);
      setIsPreparingStatus(true);
    }
  };

  const hide = () => {
    setIsVisible(false);
  };

  return (
    <Dialog
      open={isVisible}
      // onClose={hide} // Commented to prevent closing when pressing on backdrop
      TransitionComponent={Slide}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.background.main,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {!isLoadingStatuses ? (
        <>
          {!isPreparingStatus ? (
            <div className="status-modal-container">
              <img
                className="status-img"
                src={currentStatus?.image}
                alt="Coach status"
                loading="lazy"
              />

              <div
                className="status-text-container"
                style={{ backgroundColor: lightTheme.palette.quaternary.main }}
              >
                {currentStatus?.title ? (
                  <div>
                    <span
                      className="status-title"
                      style={{ color: lightTheme.palette.text.main }}
                    >
                      {currentStatus?.title}
                    </span>
                  </div>
                ) : null}
                {currentStatus?.dateTime ? (
                  <span
                    className="status-dateTime"
                    style={{ color: lightTheme.palette.text.main }}
                  >
                    {howLongAgo(
                      moment(
                        currentStatus.dateTime &&
                          currentStatus.dateTime.toDate &&
                          currentStatus.dateTime.toDate()
                      )
                    )}
                  </span>
                ) : null}
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: theme.palette.background.main }}>
              <CircularProgress
                color="primary"
                sx={{ backgroundColor: "transparent" }}
              />
            </div>
          )}

          <IconButton
            size="medium"
            onClick={handleLeftPressed}
            sx={{
              position: "fixed",
              top: "50%",
              left: 10, // Fixed margin relative to the screen
              transform: "translateY(-50%)",
              zIndex: 999, // Above the backdrop and modal
            }}
          >
            <FaChevronLeft />
          </IconButton>
          <IconButton
            size="medium"
            onClick={handleRightPressed}
            sx={{
              position: "fixed",
              top: "50%",
              right: 10, // Fixed margin relative to the screen
              transform: "translateY(-50%)",
              zIndex: 999,
            }}
          >
            <FaChevronRight />
          </IconButton>
        </>
      ) : (
        <div style={{ backgroundColor: theme.palette.background.main }}>
          <CircularProgress color="primary" />
        </div>
      )}

      <div className="status-modal-counter-container">
        <span style={{ color: theme.palette.text.main }}>
          {currentStatusIndex + 1}/{coachStatusesCounter}
        </span>
      </div>

      <IconButton
        size="medium"
        onClick={hide}
        sx={{
          position: "fixed",
          top: 30,
          right: 10, // Fixed margin relative to the screen
          transform: "translateY(-50%)",
          zIndex: 999,
        }}
      >
        <FaTimes />
      </IconButton>
    </Dialog>
  );
};

export default React.memo(StatusOverlay);
