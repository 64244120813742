import React, { useState } from "react";
import moment from "moment";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { addMonth, isMonthCreated } from "../../api/firestore/months";
import "./style.scss";

const MonthPickerOverlay = (props) => {
  const [monthSelected, setMonthSelected] = useState(props.date);

  const onMonthChange = async (item) => {
    const newMonth = moment(item);

    const isTheMonthCreated = await isMonthCreated(newMonth);
    if (!isTheMonthCreated) {
      addMonth(newMonth);
    }

    props.setDateChange && props.setDateChange(newMonth); // Set month for Edit Screen UI

    // Update target in fb
    props.handleUpdate({
      ...props.goal,
      date: newMonth,
      newDate: newMonth,
    });
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => {
        onMonthChange(monthSelected);
        props.setIsVisible(false);
      }}
      TransitionComponent={Slide}
      maxWidth="xs"
      fullWidth
      className="month-picker-modal"
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Pick a month</label>
      </DialogTitle>
      <DialogContent className="months-container">
        <div style={{ padding: 10 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              minDate={moment(props.minDate)}
              maxDate={moment().add(1, "years")}
              value={monthSelected}
              onChange={(newValue) => {
                setMonthSelected(newValue);
                onMonthChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
        </div>
      </DialogContent>

      <Divider />

      <div className="month-picker-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            onMonthChange(monthSelected);
            props.setIsVisible(false);
          }}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(MonthPickerOverlay);
