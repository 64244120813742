import firebase from "firebase/compat/app";
import { firestore, auth } from "../firebase";
import { toast } from "react-toastify";
import { captureException } from "@sentry/react";
import moment from "moment";

import {
  updateLastTimeActive,
  //  getCounters
} from "./users";

import { getTimestamp, clearNotificationsBadge } from "../helper";

export const addMessage = async (message) => {
  try {
    const userUid = auth.currentUser.uid;

    const finalMessage = {
      ...message,
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: userUid,
      ...(message.isTargetAchieved && {
        isTargetAchieved: getTimestamp(moment(message.isTargetAchieved)),
      }),
      ...(message.isTargetAdded && {
        isTargetAdded: getTimestamp(moment(message.isTargetAdded)),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new message
    const messageRef = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages")
      .doc();
    batch.set(messageRef, finalMessage);

    // Update lastMessage
    const chatRef = firestore.collection("chats").doc(userUid);
    batch.set(
      chatRef,
      {
        latestMessage: finalMessage.text || "File sent",
      },
      { merge: true }
    );

    // Update unreadClientMessages
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        unreadClientMessages: firebase.firestore.FieldValue.increment(1),
      },
      { merge: true }
    );

    updateLastTimeActive();

    // ---- Finish transaction ----

    // OLD: return await (batch.commit() only runs online)
    batch.commit().catch((error) => {
      captureException("Error while commiting message transaction", error);
      console.error("Error while commiting message transaction: " + error);
    });
    return messageRef.id;
  } catch (error) {
    captureException("Error while adding message", error);
    console.error("Error while adding message: " + error);
  }
};

export const updateMessage = async (
  messageUid,
  properties,
  toastMessage = false
) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new message
    const messageRef = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages")
      .doc(messageUid);
    batch.set(messageRef, properties, { merge: true });

    // If client set or removed reaction, increase or decrement unreadClientMessages
    if ("clientReaction" in properties) {
      if (properties.clientReaction === "") {
        // TODO - Fix before enabling this, cause you might delete the clientReaction of an already seen clientReaction
        // // Decrement unreadClientMessages
        // const clientCounters = await getCounters();
        // if (clientCounters.unreadClientMessages > 0) {
        //   const counterRef = firestore.collection("counters").doc(userUid);
        //   batch.set(
        //     counterRef,
        //     {
        //       unreadClientMessages: firebase.firestore.FieldValue.increment(-1),
        //     },
        //     { merge: true }
        //   );
        // }
      } else {
        // Increment
        const counterRef = firestore.collection("counters").doc(userUid);
        batch.set(
          counterRef,
          {
            unreadClientMessages: firebase.firestore.FieldValue.increment(1),
          },
          { merge: true }
        );
      }
    }

    // ---- Finish transaction ----
    batch
      .commit()
      .then(() => {
        if (toastMessage) {
          toast.success(toastMessage);
        }
      })
      .catch((error) => {
        console.error("Error while commiting message transaction: ", error);
      });
  } catch (error) {
    console.error("Error while updating message: ", error);
  }
};

export const deleteMessage = async (message, showToast = true) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete message
    const messageRef = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages")
      .doc(message.id);
    batch.delete(messageRef);

    // Update lastMessage
    const chatRef = firestore.collection("chats").doc(userUid);
    batch.set(
      chatRef,
      {
        latestMessage: "Deleted message", // TODO decide what to do here
      },
      { merge: true }
    );

    // TODO - Fix before enabling this, cause you might delete the clientReaction of an already seen clientReaction
    // Decrement unreadClientMessages
    // const clientCounters = await getCounters();
    // if (clientCounters.unreadClientMessages > 0) {
    //   const counterRef = firestore.collection("counters").doc(userUid);
    //   batch.set(
    //     counterRef,
    //     {
    //       unreadClientMessages: firebase.firestore.FieldValue.increment(-1),
    //     },
    //     { merge: true }
    //   );
    // }

    if (showToast) {
      setTimeout(() => {
        toast.success("Message deleted!", 2);
      }, 1);
    }

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting delete message transaction",
        error
      );
      console.error(
        "Error while commiting delete message transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while deleting message", error);
    console.error("Error while deleting message: " + error);
  }
};

export const markMessagesAsRead = (userId, tokens) => {
  try {
    // Clear notifications badge on mobile
    clearNotificationsBadge(userId, tokens);

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Reset unreadCoachMessages
    const counterRef = firestore.collection("counters").doc(userId);
    batch.set(
      counterRef,
      {
        unreadCoachMessages: 0,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting markMessagesAsRead transaction",
        error
      );
      console.error(
        "Error while commiting markMessagesAsRead transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while markMessagesAsRead", error);
    console.error("Error while markMessagesAsRead: " + error);
  }
};

export const addWelcomeMessage = async (coachId, coachName, text) => {
  try {
    const userUid = auth.currentUser.uid;

    const finalMessage = {
      text,
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: coachId,
      senderName: coachName,
      isSystemMessage: false,
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new message
    const messageRef = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages")
      .doc();
    batch.set(messageRef, finalMessage);

    // Update lastMessage
    const chatRef = firestore.collection("chats").doc(userUid);
    batch.set(
      chatRef,
      {
        latestMessage: finalMessage.text || "File sent",
      },
      { merge: true }
    );

    // Update unreadCoachMessages
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        unreadCoachMessages: firebase.firestore.FieldValue.increment(1),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException("Error while commiting message transaction", error);
      console.error("Error while commiting message transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding message", error);
    console.error("Error while adding message: " + error);
  }
};

export const addWelcomeVideo = async (coachId, coachName, video) => {
  try {
    const userUid = auth.currentUser.uid;

    const finalMessage = {
      video: { url: video?.url, thumbnail: video?.thumbnail || "" },
      sentAt: firebase.firestore.FieldValue.serverTimestamp(),
      sentBy: coachId,
      senderName: coachName,
      ...(video?.isWelcomeVideo && {
        isWelcomeVideo: true,
      }),
      ...(video?.isWelcomeVideoWithCta && {
        isWelcomeVideoWithCta: true,
      }),
      isSystemMessage: false,
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new message
    const messageRef = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages")
      .doc();
    batch.set(messageRef, finalMessage);

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException(
        "Error while commiting video message transaction",
        error
      );
      console.error(
        "Error while commiting video message transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while adding message", error);
    console.error("Error while adding message: " + error);
  }
};
