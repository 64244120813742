import { firestore } from "../firebase";

import { captureException } from "@sentry/react";

export const updateCoachStatus = (coachId, statusId, properties) => {
  try {
    // ---- Start transaction ----
    const batch = firestore.batch();

    const coachStatusRef = firestore
      .collection("coachStatuses")
      .doc(coachId)
      .collection("coachStatuses")
      .doc(statusId);
    batch.update(coachStatusRef, properties);

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting coach status transaction", error);
      console.error("Error while commiting coach status transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating coach status", error);
    console.error("Error while updating coach status: " + error);
  }
};
