// App version
export const version = "2.5.9";

// Colors
export const lightTheme = {
  colors: {
    primary: "#6ab04c",
    secondary: "#4079e4",
    tertiary: "#fbce02",
    quaternary: "#f1f6f7",
    background: "#FFFFFF",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    subtext: "#808080",
    border: "rgb(199, 199, 204)",
    notification: "rgb(255, 69, 58)",
    cardBackground: "rgb(255, 255, 255)",
    dataInput: "#FFFFFF",
    chatBackground: "#ECE5DD",
    messageBubbleSentBackground: "#FFFFFF",
    messageBubbleSentBackgroundOverWhite: "rgb(242, 242, 242)",
    messageBubbleReceivedBackground: "#FDF8E2",
    sectionsDivider: "#E9E9EB",
    gold: "#fccd00",
    nextButtonTutorial: "#f1f6f7",
  },
};

export const colors = {
  dark: "#000000",
  light: "#FFFFFF",
  darkGray: "#8C939F",
  gray: "#ababab",
  lightGray: "#cccccc",
  hyperlink2: "#5c2b92",
  milestoneGradientFirst: "#fb470c",
  milestoneGradientSecond: "#f2b31a",
  monthlyResultsGradientFirst: "#1772a6",
  monthlyResultsGradientSecond: "#4ebaf6",
};

export const alertColors = {
  Success: "#badc58",
  Warning: "#f9ca24",
  Error: "#eb4d4b",
  backgroundSuccess: "#d4edda",
  backgroundWarning: "#fff3cd",
  backgroundError: "#f8d7da",
  borderSuccess: "#c3e6cb",
  borderWarning: "#ffeeba",
  borderError: "#f5c6cb",
};

export const tagColors = [
  { id: 1, color: "#D50000" },
  { id: 2, color: "#F4511E" },
  { id: 3, color: "#0B8043" },
  { id: 4, color: "#3F51B5" },
  { id: 5, color: "#8E24AA" },
  { id: 6, color: "#E67C73" },
  { id: 7, color: "#F6BF26" },
  { id: 8, color: "#33B679" },
  { id: 9, color: "#039BE5" },
  { id: 10, color: "#7986CB" },
];

export const recurrencePatternEnum = {
  NONE: "none",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

// Navigation
export const ScreenNames = {
  SIGN_IN_SCREEN: "SIGN_IN_SCREEN",
  GOALS_SCREEN: "GOALS_SCREEN",
  INBOX_SCREEN: "INBOX_SCREEN",
  TARGETS_SCREEN: "TARGETS_SCREEN",
  MILESTONES_SCREEN: "MILESTONES_SCREEN",
  CHAT_SCREEN: "CHAT_SCREEN",
  SETTINGS_SCREEN: "SETTINGS_SCREEN",
  PROFILE_SCREEN: "PROFILE_SCREEN",
};

export const Devices = {
  PHONE: "PHONE",
  LAPTOP: "LAPTOP",
  TABLET: "TABLET",
  DESKTOP: "DESKTOP",
};

export const DeviceSize = {
  PHONE: 600,
  TABLET: 769,
  LAPTOP: 992,
  DESKTOP: 1200,
};

// Store URL's
export const storeUrls = {
  ios: "https://apps.apple.com/app/id1544973743",
  android:
    "https://play.google.com/store/apps/details?id=com.goalswon.accountability.coach.android",
};

// Messages, examples...
export const motivateMessagesStart = ["Have a"];

export const motivateMessages = [
  "great",
  "good",
  "fab",
  "wonderful",
  "positive",
  "brilliant",
  "fantastic",
  "terrific",
  "super",
  "productive",
  "superb",
  "fun",
  "top notch",
  "nice",
];

export const goalsExamples = [
  "Meditate on a boat 🧘🏻‍♀️",
  "Jog backwards after lunch ⏳",
  "Add self destruct button to app 💣",
  "50 squats while at Starbucks ☕",
  "Read Gardening for Toddlers 👶🏻",
  "Pitch best idea ever to my boss 💡",
  "Learn to code in Guava 🍈",
  "Resist the cookies! 👿",
  "Write apology to Mrs Maple 🙏🏻 ",
  "Spend 2h on macro to save 1h 🤔",
  "Plan whale watching festival 🐳",
  "Get rubber ducky repairer role 🦆",
  "Buy nuclear powered microwave 💥",
  "Tidy my underground lair 🧹",
  "Invent the windless windsurf 🤑",
  "Attend Ultimate Softball practice 🥎",
  "Dance like no one's watching 🙈",
  "Adopt a pet rock 😍",
  "Play fetch with a strangers dog 🐶",
  "Water my favorite tree 🚿",
  "Decorate my airfryer 🎄",
  "Chat with neighbourhood squirrel 🐿",
  "Re-sort bookshelf by no. pages 🤓",
  "Love nature: bark at the moon 🌚",
  "Invent the pumpkin spice lettuce 🎃",
  "Call random no. & make friend 👋🏻",
  "Remove clothes from treadmill 👕",
  "Volunteer as human scarecrow 😶",
  "Find snow and then ski on it 🎿",
  "Recover from intense video call 🥶",
  "Reply to email that I won $1M 🙄",
  "Thank kale for being so healthy 🙇🏻‍♂️ ",
  "Eat 53 blueberries (maximum) 👩🏻‍⚖️",
  "Avoid avoiding leg day 🦵",
  "Invent new dance no one can do 🕺🏻",
  "Intimidate a tough spreadsheet 🔍",
  "Call old friend and young one too ✉",
  "Have dream within a dream 💭",
  "Impose curfew on kitchen ants 🚫",
  "Stretch weird muscle 🩺",
  "Walk on by (that fast food place) ☯",
  "Tell man don't act weird in public 🤝🏻",
  "Offer free hugs with no refunds 🤗",
  "Practice slides at the station 🚅",
  "Measure my cactus's height 🌵",
  "Print out all my crypto 🏴‍☠️",
  "Invest in lentil futures 💹",
  "Procrastinate at procrastination 💪🏻",
  "Say thx to all who liked my post 😘",
  "Successfully avoid scooter 🛴",
  "Volunteer as celebrity tribute 🙋🏻‍♂️",
  "Narrowly lose to boss at golf 🏌🏻‍♂️",
  "Take luxurious (oat) milk bath 🛀🏻",
  "Buy awkward lycra bikesuit 🚴🏻‍♂️",
  "Learn magic spell from internet 🧙🏻‍♀️",
  "Make fax machines cool again 🦹🏻‍♂️",
  "Stick to selfie budget (under 10) 🤳🏻",
  "Delight neighbours with drums 🥁",
  "Perform unhammered nail audit 🔨",
  "Buy pool table for the bathroom 🎱",
  "Discover I don't have a CD player 💿",
  "Make case for an office puppy 👔",
  "Set shopping speedrun record 🛒",
  "Decorate lunch with a bow 🎀",
  "Pretend to know football 🏈",
  "Adopt a shelter teddybear 🧸",
  "Relearn how to use a radio 📻",
  "Apply double-negative mentality 🥋",
  "Build brick from smaller bricks 🧱",
  "Make kitchen admission tickets 🎫",
  "Buy self a diamond (why wait) 💎",
  "Practice snorkel in the bath 🥽",
  "Paint a picture in my mind 🎨",
  "Wear graduation hat to interview 🎓",
  "Predict future with a discoball 🔮",
  "Make dope beat for my mixtape 🎧",
  "Reactivate Friendster account 👴🏻",
  "Discover you don't own a VCR 📼",
  "Lock up the muffins (seriously) 🔒",
  "Put a pin in it 📌",
  "Find Bitcoin on old disc 💾",
  "Color match with skateboard 🛹",
  "Light candle for all fallen candles 🕯",
  "Figure out how magnets work 🧲",
  "Tap into neighbours wifi 📡",
  "Fly drone over the orchestra 🎻",
  "Threaten tomato with a knife 🔪",
  "Attach $0 labels to my clothes 🔖",
  "Make a citizen self arrest 🔫",
  "Celebrate champagne (again) 🍾",
  "Not be disappointed by avocado 🥑",
  "Save planet by scootering 🛴",
  "Finally get that haircut 💈",
  "Fire-proof the kitchen 🧯",
  "Wait patiently for shooting star 🌠",
  "Hit the road (then apologize) 🛣",
  "Finish project 2 sec b4 deadline 🎌",
  "Negotiate the office temp up 🥶",
  "Smile today (no reason reqd) 🌞",
  "Invest in carpet insurance 🔑",
  "Finally dust those cobwebs 🕸",
  "Plant a money tree 🌲",
  "Stop and smell the roses 🌹",
  "Watch video on turnip carving 🔪",
  "Re-attempt new dance craze 🚑",
  "Forget what I did last summer 🙊",
  "Practice electric boogaloo 🤖",
  "Downsize my kids toy area 💢",
  "Borrow Life of Pie from library 🍰",
  "Hug a lonely tree 🌴",
  "Join IKEA-aholics anonymous 🪓",
  "Invent triangle shaped brick 📐",
  "Try to figure out what an NFT is 🙄",
  "Report scam to authorities 🙅‍♂️",
  "Gift slice of cake to neighbour 🍰",
  "Ship version 0.9.9.9.9 of my app 🤞",
  "Schedule time to schedule ⌛",
  "Call meeting about the meeting 🎯",
  "Outsource my daily exercise 🏖",
  "Go bee spotting 🐝",
  "Get excited about new airfryer 🤸‍♀️",
  "Find San Marino on a map 🌎",
  "Reboot my smart teepee 💻",
  "Watch Fast & Furious 27 🔥",
  "Make counteroffer to cashier 🏪",
  "Totally not postpone my taxes 👻",
  "Fill long form without saving 💣",
  "Regret dog walking degree 🐩",
  "Ask internet about pet lobsters 🦞",
  "Keep up with bomb technician 🤯",
  "Complain about kid on lawn 👨‍🌾",
  "Discover joy of nutritional yeast 👩‍🍳",
  "Celebrate world pancake day 🎆",
  "Compliment fine looking cabinet 💋",
  "Craft the perfect dad joke 👨",
  "Check on Siri, she seems bored 🥱",
  "Reevaluate need for a toaster 🍞",
  "Seize moment, multiple times 🎉",
  "Rescue drone from scary house 🎃",
  "Buy robot back scratcher 🤖",
  "Wax on, wax off, repeat ☯",
  "Brush the toothbrush 🧹",
  "Polish trophy cabinet 🧽",
  "Seek YouTube downvote button 👎",
  "Give grass a haircut 🦗",
  "Go overboard with putty 😎",
  "Invent generic playdough 😶",
  "Label all piano keys in order 🎹",
  "Photoshop out mud from pic 🤠",
  "Surf information superhighway 🐱‍🐉",
  "Hack into public smart toilet 🦀",
  "Host board meeting at pub 🍺",
  "Launch hostile takeover of pillow 😴",
  "Run like a fish. Swim like a deer 🐟",
  "Lower Goodreads book target 😌",
  "Practice speech in VR 👋",
  "Have weird metaverse moment 😱",
  "Rescue cat from bubblebath 😾",
  "Learn to like kimchi 😖",
  "Buy VR headset and VR socks 🧦",
  "Buy Doritos stock on the dip 📊",
  "Set Eye of the Tiger as ringtone 🐯",
  "Google what a bidet is 🤔",
  "Try cold-hot-cold shower hack 🚿",
  "Locate tibialis posterior muscles 🧘‍♂️",
  "Write hamster's wellness journal 🐹",
  "Buy way overpowered e-scooter 💀",
  "Dream about dream job 😎",
  "Buy microwavable ski gloves 🧤",
  "Setup an AI to reply to the boss 🤖",
  "Upgrade to a rowing desk 👔",
  "Create cactus sunflower hybrid 🌻",
  "Categorize the 50 shades of hay 🤠",
  "Practice office jokes on the dog 🐶",
  "Rotate the bedsheets 🛌",
  "Bark back at neighbours dog 🐩",
  "Treat self to mud massage 🙌",
  "Rescue grandma from casino 🐱‍🐉",
  "Release static electricity ⚡",
  "Upgrade hamster wheel rims 💎",
  "Buy toy nuclear reactor for kid 😍",
];

export const targetsExamples = [
  "Open a doggy day spa 🚿",
  "Finish burger decoration course 🍔",
  "Organize fitness festival 💪🏻",
  "Learn to play the gong 🎵",
  "Qualify for Jazzercise state final 🥇",
  "Launch 'Airbnb for Puppies' 🐶",
  "Teach my cat a new trick 😹",
  "Launch disposable backpack 🎒",
  "Write LOTR fanfiction (in Elvish) ✒",
  "Install a backyard ferris wheel 🎡",
  "Design nighttime solar panels 🌒",
  "Transform 1 pack into a 6 pack 🤸🏻‍♂️",
  "Wake before the roosters do 🐓",
  "Buy Myspace and rehire Tom 🤝🏻",
  "Get Degree in Equine Education 🐴",
  "Build a robot to take out trash 🤖",
  "Lose weight for Limbopalooza 📉",
  "Start a YouTube for pet viewers 🐹",
  "Adopt 'Rawr Til You Snore' diet 🦁",
  "Write a bestselling dictionary 📕",
  "Fly to Hawaii, return by canoe 🛶",
  "Somehow make pingpong cool 🏓",
  "Design helmet for traffic cones 🚦",
  "Rotate the mattress 360 degrees ↪",
  "Design the ultimate pancake 👩🏻‍🍳",
  "Organize living room camping 🎒",
  "Get dream job as a cat declawer 😾",
  "Study toy car maintenance 🔧",
  "Create a back scratching robot 🔙",
  "Re-learn long division ➗",
  "Run for mayor of the kitchen 🍎",
  "Learn morse code via blinking 👁",
  "Meet soulmate at the shoe shop 👠",
  "Rename deadlift more positively 🏋🏻‍♀️",
  "Franchise trampoline yoga biz 💰",
  "Install security camera in fridge 📷",
  "Start fermenting a 10 year kimchi ⏳",
  "Kick the cupcake addiction 🧁",
  "Start an adventure capital fund 🚀",
  "Hunt best ever fortune cookie 🥠",
  "Buy crypto with cute names 🐾",
  "Start Unicorn support group 🦄",
  "Learn synthesizer (that is all) 🎹",
  "Hike >3% of the appalachian trail 🚶🏻‍♀️",
  "Write final GOT book myself 🐲",
  "Discover what's under the sofa 🏺",
  "Travel to all continents but AUS 🐨",
  "Invent baby translator & retire 👼🏻",
  "Do spring cleaning every season 🧹",
  "Organize local hunger games 🏹",
  "Give unruly hedge stern lecture 📣",
  "Be first to eat popcorn in space 👩🏻‍🚀",
  "Discover who stole printer paper 🕵🏻‍♀️",
  "Figure out if we're in the matrix 👩🏻‍💻",
  "Learn to juggle fruit 🤹🏻‍♂️",
  "Conquer the backyard weeds 🤴🏻",
  "Level up my karaoke 🎤",
  "Plant tree for future-you shade 🌴",
  "Code AI for salad-generator app 🥗",
  "Devise plan to thwart Skynet 🌌",
  "Win Prancing with the Stars ⭐",
  "Try intermittent rafting 🛶",
  "Hand in vape nation passport 🚭",
  "Build tree house with garage 🏘",
  "Learn to duck dive from ducks 🏄‍♂️",
  "Live life on the edge of the sofa ⚡",
  "Learn from cat mittens failure 😾",
  "Have intense warm-down 💪",
  "Compare potato varieties 🔬",
  "Learn about the history of taxes 😴",
  "Study rocket science for cred 🚀",
  "Plan for future ape uprising 🐵",
  "Do Lego cost benefit analysis 🧱",
  "Get competent in cat wrangling 🐱‍🐉",
  "Follow dreams (of sheep) 🐑",
  "Plan dream trip to local park 🏞",
  "Prepare for all-you-can-eat buffet 😋",
  "Plan side project's side project 🪐",
  "Finally do ice bucket challenge 🧊",
  "Launch Smoothies as a service 🧉",
  "Make 2 new friends, 1 enemy 🤺",
  "Stack eating cake after baking it 🍰",
  "Join hay prediction market 👨‍🌾",
  "Run for and from local office 🏃‍♀️",
  "Research the perfect blender 👨‍🍳",
  "Figure out where the ants enter 🐜",
  "Unleash perfect dad joke 😨",
  "Beat Santa imposter syndrome 🎅",
  "Escape stuck chimney 🏠",
  "Train AI to predict next big hit 🎶",
  "Compile the uncompilable 💪 ",
  "Install toilet wall projector 🎬",
  "Learn happy birthday in Vulcan 🖖",
  "Learn to kick flip (a pancake 🥞)",
  "Try the hot new waterfall fast 🌊",
  "Find out what is sulforaphane 🥦",
  "Learn ethical sweet talk 👻",
  "Organize bulk buy hay discount 👩‍🌾",
  "Buy Wim Hof warm clothes 🧥",
  "Plan hazardous hiking holiday 💀",
  "Join Alaskans Anonymous ⛔",
  "Craft world's best cake pyramid 🐱",
  "Patent diet-water forumlation 💰",
];

// Guide steps
export const guideSteps = [
  {
    id: "intro",
    attachTo: { element: ".headerButton", on: "bottom" },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    // buttons: [
    //   {
    //     classes: "shepherd-button-secondary",
    //     text: "Exit",
    //     type: "cancel",
    //   },
    //   {
    //     classes: "shepherd-button-primary",
    //     text: "Back",
    //     type: "back",
    //   },
    //   {
    //     classes: "shepherd-button-primary",
    //     text: "Next",
    //     type: "next",
    //   },
    // ],
    // classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    // title: "Welcome to React-Shepherd!",
    text: ["Tap here to get to know your coach"],
    when: {
      // complete: function () {
      //   console.log("complete");
      // },
      // hide: function () {
      //   console.log("hide");
      // },
      // destroy: function () {
      //   console.log("destroy");
      // },
      // cancel: function () {
      //   console.log("cancel");
      // },
      // next: function () {
      //   console.log("next");
      // },
    },
  },
  // ...
];

export const EMOJIS = ["👍", "😂", "❤️", "🙌", "👏"];

export const USER_TYPE = {
  PREMIUM: "premium",
  FREE: "free",
  EA: "ea",
  INACTIVE: "inactive",
};

export const SUBSCRIPTION_PLATFORM = {
  STRIPE: "stripe",
  APPLE: "apple",
  PAYPAL: "paypal",
};

export const endpointUrls = {
  URL_CLEAR_NOTIFICATIONS_BADGE:
    "https://us-central1-goalswon-rebuilt-app.cloudfunctions.net/app2/api/clearNotificationsBadge",
};
