import React, { useCallback } from "react";
import Linkify from "react-linkify";

// Components
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import "./style.scss";

const InfoOverlay = ({
  isVisible,
  setIsVisible,
  handleConfirmation,
  title,
  subtitle,
  textOk,
}) => {
  const theme = useTheme();

  // Hyperlinks
  const componentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
      style={{
        color: theme.palette.primary.main,
      }}
    >
      {text}
    </a>
  );

  const onDonePressed = useCallback(() => {
    handleConfirmation && handleConfirmation();
    setIsVisible(false);
  }, []);

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      maxWidth="xs"
      fullWidth
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
    >
      {title ? (
        <DialogTitle className="modal-title">
          <label className="modal-title-label">{title}</label>
        </DialogTitle>
      ) : null}

      <DialogContent sx={{ padding: 0 }}>
        <div
          className="info-overlay-content"
          style={{
            marginBottom: subtitle ? 25 : 0,
          }}
        >
          {subtitle ? (
            <Linkify
              componentDecorator={(href, text, key) =>
                componentDecorator(href, text, key)
              }
            >
              <p className="subtitle">{subtitle}</p>
            </Linkify>
          ) : null}
        </div>
      </DialogContent>

      <Divider />

      <div className="modal-footer" onClick={onDonePressed}>
        <Button
          sx={{
            borderRadius: 0,
            height: 50,
          }}
          color="primary"
          onClick={() => {
            handleConfirmation && handleConfirmation();
            setIsVisible(false);
          }}
        >
          {textOk || "Done"}
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(InfoOverlay);
