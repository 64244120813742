// Local
export const DARK_MODE = "DARK_MODE";
export const SUBMIT_DAY_REMINDER = "SUBMIT_DAY_REMINDER";
export const ADD_GOALS_REMINDER = "ADD_GOALS_REMINDER";
export const CHAT_NOTIFICATIONS = "CHAT_NOTIFICATIONS";
export const SORT_BY_IS_DONE = "SORT_BY_IS_DONE";
export const SORT_BY = "SORT_BY";
export const ORDER_BY = "ORDER_BY";
export const TARGETS_SORT_BY_IS_DONE = "TARGETS_SORT_BY_IS_DONE";
export const TARGETS_SORT_BY = "TARGETS_SORT_BY";
export const TARGETS_ORDER_BY = "TARGETS_ORDER_BY";
export const LAST_SEEN_RELEASE = "LAST_SEEN_RELEASE";
export const GOAL_COMPLETE_SOUND = "GOAL_COMPLETE_SOUND";
export const CHAT_MESSAGES_SOUND = "CHAT_MESSAGES_SOUND";
export const SOUND_EFFECTS = "SOUND_EFFECTS";
export const SHOWED_GOAL_EXAMPLE_AT = "SHOWED_GOAL_EXAMPLE_AT";
export const SHOWED_TARGET_EXAMPLE_AT = "SHOWED_TARGET_EXAMPLE_AT";
export const RANDOM_NUMBER_MOTIVATE_MESSAGE_START =
  "RANDOM_NUMBER_MOTIVATE_MESSAGE_START";
export const RANDOM_NUMBER_MOTIVATE_MESSAGE = "RANDOM_NUMBER_MOTIVATE_MESSAGE";
export const RANDOM_NUMBER_GOALS_EXAMPLE = "RANDOM_NUMBER_GOALS_EXAMPLE";
export const RANDOM_NUMBER_TARGETS_EXAMPLE = "RANDOM_NUMBER_TARGETS_EXAMPLE";
export const CHANGE_CURRENT_SCREEN = "CHANGE_CURRENT_SCREEN";
export const CURRENT_DEVICE = "CURRENT_DEVICE";
export const SUPPORTER_SETTINGS = "SUPPORTER_SETTINGS";
export const SHOW_TARGETS_HISTORY = "SHOW_TARGETS_HISTORY";
export const SHOW_CURRENT_MONTH_TARGETS = "SHOW_CURRENT_MONTH_TARGETS";
export const MOOD_BUTTONS_OPTIONALITY = "MOOD_BUTTONS_OPTIONALITY";
export const LAST_TIME_TOGGLED_GOAL_DONE = "LAST_TIME_TOGGLED_GOAL_DONE";
// export const MANUAL_TIMEZONE = "MANUAL_TIMEZONE";
export const HOLIDAY_MODE = "HOLIDAY_MODE";

// Day
export const FETCH_DAY = "FETCH_DAY";
export const SET_LOADING_DAY = "SET_LOADING_DAY";
export const FETCH_TODAY = "FETCH_TODAY";
export const SET_LOADING_TODAY = "SET_LOADING_TODAY";
export const FETCH_YESTERDAY = "FETCH_YESTERDAY";
export const SET_LOADING_YESTERDAY = "SET_LOADING_YESTERDAY";

// Goal
export const FETCH_GOALS = "FETCH_GOALS";
export const SET_LOADING_GOALS = "SET_LOADING_GOALS";
export const FETCH_YESTERDAY_GOALS = "FETCH_YESTERDAY_GOALS";
export const SET_LOADING_YESTERDAY_GOALS = "SET_LOADING_YESTERDAY_GOALS";

// Recurrent Goal
export const FETCH_RECURRENT_GOALS = "FETCH_RECURRENT_GOALS";
export const SET_LOADING_RECURRENT_GOALS = "SET_LOADING_RECURRENT_GOALS";
export const FETCH_YESTERDAY_RECURRENT_GOALS =
  "FETCH_YESTERDAY_RECURRENT_GOALS";
export const SET_LOADING_YESTERDAY_RECURRENT_GOALS =
  "SET_LOADING_YESTERDAY_RECURRENT_GOALS";

// Inbox
export const FETCH_INBOXES = "FETCH_INBOXES";
export const SET_LOADING_INBOXES = "SET_LOADING_INBOXES";

// Milestone
export const FETCH_MILESTONES = "FETCH_MILESTONES";
export const SET_LOADING_MILESTONES = "SET_LOADING_MILESTONES";

// Target
export const FETCH_TARGETS = "FETCH_TARGETS";
export const SET_LOADING_TARGETS = "SET_LOADING_TARGETS";
export const FETCH_MONTH_TARGETS = "FETCH_MONTH_TARGETS";
export const SET_LOADING_MONTH_TARGETS = "SET_LOADING_MONTH_TARGETS";
export const FETCH_LAST_MONTH_TARGETS = "FETCH_LAST_MONTH_TARGETS";
export const SET_LOADING_LAST_MONTH_TARGETS = "SET_LOADING_LAST_MONTH_TARGETS";
export const FETCH_CURRENT_MONTH_TARGETS = "FETCH_CURRENT_MONTH_TARGETS";
export const SET_LOADING_CURRENT_MONTH_TARGETS =
  "SET_LOADING_CURRENT_MONTH_TARGETS";
export const FETCH_NEXT_MONTH_TARGETS = "FETCH_NEXT_MONTH_TARGETS";
export const SET_LOADING_NEXT_MONTH_TARGETS = "SET_LOADING_NEXT_MONTH_TARGETS";

// Chat
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const SET_LOADING_MESSAGES = "SET_LOADING_MESSAGES";
export const SET_LOADING_MORE_MESSAGES = "SET_LOADING_MORE_MESSAGES";

// Counter
export const FETCH_COUNTERS = "FETCH_COUNTERS";
export const SET_LOADING_COUNTERS = "SET_LOADING_COUNTERS";

// User
export const FETCH_USER = "FETCH_USER";
export const SET_LOADING_USER = "SET_LOADING_USER";

// Coach
export const FETCH_COACH = "FETCH_COACH";
export const SET_LOADING_COACH = "SET_LOADING_COACH";

// Releases
export const FETCH_RELEASES = "FETCH_RELEASES";
export const SET_LOADING_RELEASES = "SET_LOADING_RELEASES";

// Month
export const FETCH_MONTH = "FETCH_MONTH";
export const SET_LOADING_MONTH = "SET_LOADING_MONTH";
export const FETCH_LAST_MONTH = "FETCH_LAST_MONTH";
export const SET_LOADING_LAST_MONTH = "SET_LOADING_LAST_MONTH";
export const FETCH_CURRENT_MONTH = "FETCH_CURRENT_MONTH";
export const SET_LOADING_CURRENT_MONTH = "SET_LOADING_CURRENT_MONTH";

// Quiz
export const FETCH_QUIZ = "FETCH_QUIZ";
export const SET_LOADING_QUIZ = "SET_LOADING_QUIZ";

// Tags
export const FETCH_TAGS = "FETCH_TAGS";
export const SET_LOADING_TAGS = "SET_LOADING_TAGS";

// Alerts
export const FETCH_ALERTS = "FETCH_ALERTS";
export const SET_LOADING_ALERTS = "SET_LOADING_ALERTS";

// Coach statuses
export const FETCH_COACH_STATUSES = "FETCH_COACH_STATUSES";
export const SET_LOADING_COACH_STATUSES = "SET_LOADING_COACH_STATUSES";
