import { FETCH_COACH_STATUSES, SET_LOADING_COACH_STATUSES } from '../types';

const initialState = {
  coachStatuses: [],
  isLoading: false,
};

const coachStatusesReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_COACH_STATUSES:
      return {
        ...state,
        coachStatuses: payload,
      };

    case SET_LOADING_COACH_STATUSES:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default coachStatusesReducer;
