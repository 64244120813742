import { firestore } from "../../api/firebase";
import { captureException } from "@sentry/react";

import { FETCH_COACH_STATUSES, SET_LOADING_COACH_STATUSES } from "../types";

const fetchCoachStatusesSuccess = (statuses) => ({
  type: FETCH_COACH_STATUSES,
  payload: statuses,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_COACH_STATUSES,
  payload: isLoading,
});

export const fetchCoachStatuses = (coachId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchCoachStatusesSuccess([])); // Clear previous data if needed

    // Fetch data with .get()
    const querySnapshot = await firestore
      .collection("coachStatuses")
      .doc(coachId)
      .collection("coachStatuses")
      .get();

    // Map through the fetched documents
    const coachStatuses = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // Dispatch the fetched data and update loading state
    dispatch(fetchCoachStatusesSuccess(coachStatuses));
    dispatch(setLoading(false));
  } catch (error) {
    // Handle error capturing and logging
    dispatch(setLoading(false));
    captureException("Error fetching coach statuses", error);
    console.error("Error fetching coach statuses:", error);
  }
};
